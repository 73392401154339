import { IBrand, IColors, IFlowMessages, TBrandName, TPartnersBrand } from "@/types/whitelabel";
import axios from "axios";
import { RouteConfig } from "vue-router";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";
import ResourceReturnFactory from "@/models/factories/ResourceReturnFactory";

export interface BankOrigin {
  id: number;
  bank_id: number;
  active: boolean;
  bank: {
    id: number;
    name: string;
    short_name: string;
    number: string;
    code: string;
  };
}

export interface IWhitelabelResource {
  id: number;
  brand: TBrandName | TPartnersBrand;
  bank_origin: BankOrigin;
  page_title: string;
  favicon: string;
  login_images: string[];
  partner_code: string;
  minimal_logo: { src: string; width: number; height: number };
  logo: { src: string; width: number; height: number };
  logo_on_white: { src: string; width: number; height: number };
  contact_email: string;
  contact_whatsapp_number: string;
  contact_whatsapp_message: string;
  colors: IColors;
  logout_url: string;
  flow_messages: IFlowMessages;
  loan_values: {
    min: number;
    max: number;
    cardValues: number[];
  };
  base_url?: string;
  specific_routes: RouteConfig[];
  modality_codes: string[];
  hide_whatsapp_icon: boolean;
  message_sending_type: EMessageSendingType;
  has_sub_domains?: boolean;
  borrower_can_create_proposal?: boolean;
  borrower_can_create_user?: boolean;
  defaultPartner?: number;
  partner: {
    id: number;
    name: string;
    code: string;
    url_domain: string;
    logout_url?: string;
  };
  additional_configs?: {
    show_help_button: boolean;
    can_attach_documents: boolean;
    show_remove_tutorials_button: boolean;
    admin_can_create_proposal: boolean;
    enable_whatsapp_message_type: boolean;
    show_proposal_verification_modal?: boolean;
    show_new_simulation_button?: boolean;
    show_proposal_values?: boolean;
    use_simplified_signup?: boolean;
  };
  has_user_data_button_visible?: boolean;
  can_edit_user_data?: boolean;
}

export interface IGetPartner {
  name: string;
  code: string;
  url_domain: string;
  id: number;
  brand_id: number;
}

export default class WhitelabelResource {
  private resourceReturnFactory: typeof ResourceReturnFactory;

  constructor() {
    this.resourceReturnFactory = ResourceReturnFactory;
  }

  async getBrand(brand: string, partner_code?: string) {
    let headers = {};

    if (partner_code) {
      headers = {
        "partner-code": partner_code,
      };
    }

    try {
      const { data } = await axios.get<{ response: IWhitelabelResource }>(
        import.meta.env.VITE_VUE_APP_ONBOARD_URL + "public/brands",
        {
          headers,
          params: { brand },
        }
      );

      if (data.response) {
        const { response } = data;

        const newBrand: IBrand = {
          id: response.id,
          bankOrigin: response.bank_origin?.bank?.code || "dinheirow",
          baseUrl: response.base_url,
          borrowerCanCreateProposal: response.borrower_can_create_proposal,
          borrowerCanCreateUser: response.borrower_can_create_user,
          brand: response.brand,
          colors: response.colors,
          contactEmail: response.contact_email,
          contactWhatsappMessage: response.contact_whatsapp_message,
          contactWhatsappNumber: response.contact_whatsapp_number,
          favicon: response.favicon,
          flowMessages: response.flow_messages,
          hasSubDomains: response.has_sub_domains,
          hideWhatsappIcon: response.hide_whatsapp_icon,
          loanValues: response.loan_values,
          loginImages: response.login_images,
          logo: response.logo,
          logoOnWhite: response.logo_on_white,
          logoutUrl: response.logout_url,
          messageSendingType: response.message_sending_type,
          minimalLogo: response.minimal_logo,
          modalityCodes: response.modality_codes,
          pageTitle: response.page_title,
          specificRoutes: response.specific_routes,
          partner: response.partner,
          partnerCode: response.partner.code,
          additionalConfigs: {
            showRemoveTutorialsButton: response.additional_configs?.show_remove_tutorials_button,
            showHelpButton: response.additional_configs?.show_help_button,
            canAttachDocuments: response.additional_configs?.can_attach_documents,
            adminCanCreateProposal: response.additional_configs?.admin_can_create_proposal,
            enableWhatsappMessageType: response.additional_configs?.enable_whatsapp_message_type,
            showProposalVerificationModal:
              response.additional_configs?.show_proposal_verification_modal,
            showNewSimulationButton: response.additional_configs?.show_new_simulation_button,
            showProposalValues: response.additional_configs?.show_proposal_values,
            useSimplifiedSignup: response.additional_configs?.use_simplified_signup,
          },
          showUserData: response.has_user_data_button_visible,
          canEditUserData: response.can_edit_user_data,
        };

        return newBrand;
      }
    } catch (e) {
      return null;
    }
  }

  async getPartner(partnerCode: string) {
    try {
      const response = await axios.get<{ response: IGetPartner }>(
        import.meta.env.VITE_VUE_APP_ONBOARD_URL + `public/partners/${partnerCode}`
      );

      return this.resourceReturnFactory.create<IGetPartner>({
        object: {
          error: false,
          data: response?.data?.response || null,
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create<IGetPartner>({
        object: {
          error: true,
          data: null,
          responseObject: e,
        },
      });
    }
  }
}
